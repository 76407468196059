import "./footer.css";
import { Link, useLocation } from "react-router-dom";

function Footer(props) {
    var profile = props.profile;
    const location = useLocation();
    const data = [
        {
            icon: "nav-home",
            iconactive: "icon-gift-active",
            title: "Trang Chủ",
            to: "/"
        },
        {
            icon: "nav-promo",
            iconactive: "icon-chat",
            title: "Khuyến Mãi",
            to: "/notification"
        },
        {
            icon: "nav-reward",
            iconactive: "icon-home-active",
            title: "Nạp tiền",
            to: "/recharge"
        },
        {
            icon: "nav-mission",
            iconactive: "icon-cskh",
            title: "CSKH",
            to: "/service"
        },
        {
            icon: "nav-account",
            iconactive: "icon-user-active",
            title: "Tài Khoản",
            to: "/profile"
        }
    ];

    return (
        <>
            <div className="footer">
                {data.map((item, index) => (
                    <div className={location.pathname === item.to || location.pathname.substring(0, 9) === item.to ? "footer_item active" : "footer_item"} key={index}>
                        <Link style={{ textDecoration: "none" }} to={item.to}>
                            <div className="icon_footer">
                                <img alt="" src={require(`../../static/${item.icon}.png`)} />
                            </div>
                            <div className="title_footer">{item.title}</div>
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
}
export default Footer;
