import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Results from "./0_Results";
import History from "../../components/SidebarHistory";
import TabNavigation from "./0_Tab";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AnimatedPage from "../../components/AnimatedPage";
import { useNavigate } from "react-router-dom";

function Xoso() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/xoso");
    };
    const [bet, setBet] = useState(null);
    const [second, setSecond] = useState(15);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [newData, setNewData] = useState(null);
    const [update, setUpdate] = useState(0);
    const dates = new Date();
    const currentMinute = dates.getMinutes();
    const currentSecond = dates.getSeconds();
    const [loadGame, setLoadGame] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [total, setTotal] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [choose, setChoose] = useState([]);
    const [textArea, setTextArea] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function rollLottery(res) {
        const interval = setInterval(() => {
            const randomDigits = Math.floor(Math.random() * 90000) + 10000;
            setTotal([{ id_bet: res.data.data[0].id_bet, dacbiet: String(randomDigits) }]);
        }, 100);

        setTimeout(() => {
            clearInterval(interval);
            setTotal(res.data.data);
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/xs75s/get`).then((res) => {
            setBet(res.data.data);
            setDulieunhap(new Date(res.data.data.createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/xs75s/getallbet`, {})
            .then((res) => {
                setTotal(res.data.data);
                setNewData(res.data.data);
            })
            .catch(() => setTotal(null));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            if (profile != res.data.data) {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
           if (Math.floor(75 - (new Date() - dulieunhap) / 1000) < 0) {
                axios.get(`${process.env.REACT_APP_API_URL}/xs75s/get`).then((res) => {
                    setBet(res.data.data);
                    setDulieunhap(new Date(res.data.data.createdAt));
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/xs75s/getallbet`, {})
                    .then((res) => {
                        rollLottery(res);
                        setNewData(res.data.data);
                    })
                    .catch(() => setTotal(null));
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(75 - (dates - dulieunhap) / 1000);

        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 75 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(75 - (dates - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 75 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(15);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const openPopup1 = () => {
        setIsOpen1(true);
    };
    const closePopup1 = () => {
        setIsOpen1(false);
    };

    const [isOpen2, setIsOpen2] = useState(false);
    const openPopup2 = () => {
        setIsOpen2(true);
    };
    const closePopup2 = () => {
        setIsOpen2(false);
    };
    const fTextarea = (e) => {
        setTextArea(e.target.value.trim().replace(/[^0-9., ]/g, ""));
        const text = e.target.value.trim().replace(/[^0-9., ]/g, "");
        let splitChar;
        if (text.indexOf(" ") !== -1) {
            splitChar = " ";
        } else if (text.indexOf(".") !== -1) {
            splitChar = ".";
        } else if (text.indexOf(",") !== -1) {
            splitChar = ",";
        } else {
            setChoose([text]);
            return false;
        }
        const arr = text.split(splitChar);
        const uniqueArr = arr.filter((item, index) => arr.indexOf(item) === index);
        setChoose(uniqueArr);
        setTextArea(uniqueArr.join(","));
    };
    const onChoose = (e) => {
        if (choose.includes(e.target.id)) {
            setChoose(choose.filter((item) => item !== e.target.id));
        } else if (choose.length < setting?.maxde) {
            setChoose([...choose, e.target.id]);
        } else {
            swal("Chú ý", `Bạn chỉ được chọn tối đa ${setting?.maxde} số`, "warning");
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (loadGame == true) return false;
        setLoadGame(true);
        const newData = [];
        let notAllowed = false;
        choose.map((item) => {
            if (item.length == 1) {
                newData.push("0" + Number(item).toString().toLowerCase());
            } else if (item.length > 2) {
                notAllowed = true;
            } else if (item.length == 2) {
                newData.push(item);
            }
        });
        if (notAllowed) {
            swal("Thông báo", "Danh sách số đánh có số không hợp lệ.", "error");
            return false;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa nhập tiền", "error");
            return false;
        }
        if (newMoney * 1000 > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return false;
        }
        if (newMoney * 1000 < setting?.mincuoc) {
            swal("Chú ý", `Số tiền cược tối thiểu là ${Number(setting?.mincuoc).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (newMoney * 1000 > setting?.maxcuoc) {
            swal("Chú ý", `Số tiền cược tối đa là ${Number(setting?.maxcuoc).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (!newData || choose.length == 0) {
            swal("Thất bại", "Bạn chưa chọn số đánh", "info");
            return false;
        }
        if (choose.length > setting?.maxde) {
            swal("Chú ý", `Bạn chỉ được chọn tối đa ${setting?.maxde} số`, "warning");
            return false;
        }
        
        if (Number(second) <= 10 && Number(minute) == 0) {
            swal("Đặt cược không thành công.", " Đã hết thời gian cược", "warning");
            return false;
        } else {
            let formData = {
                state: newData.join(" "),
                id: bet?._id,
                type: 16,
                money: newData.length * newMoney * 1000
            };
            if (choose.length == 0) {
                swal("Thất bại", "Bạn chưa chọn số đánh", "info");
            } else {
                axios
                    .post(`${process.env.REACT_APP_API_URL}/history/chooseXS75s`, formData)
                    .then((res) => {
                        swal("Đặt cược thành công", "", "success").then(() => setLoadGame(false));
                        setChoose([]);
                        setTextArea("");
                        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                            if (profile != res.data.data) {
                                setProfile(res.data.data);
                                localStorage.setItem("profile", JSON.stringify(res.data.data));
                            }
                        });
                    })
                    .catch((err) =>
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error").then(() => setLoadGame(false))
                );
            }
        }
    };
    const [newMoney, setNewMoney] = useState(1);
    const numbers = Array.from(Array(100).keys());
    const date0 = new Date();
    const [date, setDate] = useState(date0.getDay());
    const [activeOption, setActiveOption] = useState("1");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Miền Bắc 75 giây</h1>
                <div className="history" onClick={openPopup2}>
                    <img src="/images/icon_time_gold.png" alt="" />
                    <span>Lịch sử</span>
                </div>
            </div>
            {bet && (
                <>
                    <AnimatedPage>
                        <div className="game-detail">
                            <img alt="" src={require(`../../../images/mb75s.png`)} />
                            <div>
                                <div className="game-detail-title" style={{ fontSize: "0.34444rem" }}>
                                    Miền Bắc 75 giây
                                </div>
                                <div className="game-detail-des">75 giây mở thưởng</div>
                            </div>
                            <div>
                                <div className="game-detail-des">Phiên: {bet?.id_bet}</div>
                                <div className="time-box">
                                    <div className="number">
                                        <div className="item">{minute < 10 ? "0" + minute : minute}</div>
                                        <div className="item">:</div>
                                        <div className="item">{second < 10 ? "0" + second : second}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="game-result" onClick={openPopup1}>
                            <div className="game-result-left">
                                <img src="/images/icon_time_gold.png" alt="" /> <span>Phiên {total && total[0]?.id_bet}</span>
                            </div>
                            <div className="game-result-center">
                                {total && total[0]?.dacbiet?.split("").map((x) => (
                                    <div className="ball">
                                        <img alt="" src={`/images/ball/${x}.png`} />
                                    </div>
                                ))}
                            </div>
                            <div className="game-result-right">
                                <KeyboardArrowDownIcon sx={{ fontSize: "0.6rem" }} />
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className="game-rate">
                                <div onClick={() => setIsOpen(!isOpen)}>
                                    Hình thức cược: Đề đầu giải nhất{" "}
                                    {isOpen ? (
                                        <KeyboardArrowUpIcon sx={{ fontSize: "0.45rem", marginBottom: "-5px" }} />
                                    ) : (
                                        <KeyboardArrowDownIcon sx={{ fontSize: "0.45rem", marginBottom: "-5px" }} />
                                    )}
                                </div>
                                <div>{setting && "Tỷ lệ cược: 1 ăn " + setting.xsn_dedau1}</div>
                            </div>
                            <div className={isOpen ? "popup-change-game active" : "popup-change-game"}>
                                <TabNavigation />
                            </div>
                        </div>
                        <div className="main-game">
                            <ul className="tab-navigation tab-game">
                                <li className={activeOption === "1" ? "active" : ""} onClick={() => handleOptionClick("1")}>
                                    Chọn số nhanh
                                </li>
                                <li className={activeOption === "2" ? "active" : ""} onClick={() => handleOptionClick("2")}>
                                    Nhập số
                                </li>
                            </ul>
                            <div className="main_game">
                                {activeOption === "1" && (
                                    <div className="text_choose_center">
                                        <div className="state_choose">
                                            {numbers.map((number) => (
                                                <div
                                                    key={number}
                                                    id={number < 10 ? `0${number}` : number}
                                                    onClick={onChoose}
                                                    className={`choose_xs  ${choose.includes(String(number < 10 ? `0${number}` : number)) ? "chooseItem" : ""}`}>
                                                    {number < 10 ? `0${number}` : number}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {activeOption === "2" && (
                                    <div className="text_choose_center">
                                        <div className="cach-choi">
                                            Cách chơi:
                                            <br />
                                            Giữa mỗi cược cần phân cách bởi dấu , hoặc khoảng trống. Ví dụ: 10,20,30 hoặc 10 20 30
                                        </div>
                                        <textarea onChange={fTextarea} value={textArea}></textarea>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="footer-game">
                            <form onSubmit={onSubmit}>
                                <div className="footer-game-top">
                                    <div className="footer-chip">
                                        <div className="chip-1x" onClick={() => setNewMoney(Number(newMoney) + 1)}></div>
                                        <div className="chip-3x" onClick={() => setNewMoney(Number(newMoney) + 3)}></div>
                                        <div className="chip-5x" onClick={() => setNewMoney(Number(newMoney + 5))}></div>
                                        <div className="chip-10x" onClick={() => setNewMoney(Number(newMoney) + 10)}></div>
                                    </div>
                                    <div>
                                        <input value={newMoney} onChange={(e) => setNewMoney(e.target.value)} min="1" name="money" type="number" />
                                    </div>
                                    <div>
                                        Đã chọn: <b style={{ margin: "0 0.15rem" }}>{choose.length}</b> lô
                                    </div>
                                </div>
                                <div className="footer-game-bottom">
                                    <button
                                        type="reset"
                                        className="btn-reset"
                                        style={{ opacity: "0.8" }}
                                        onClick={() => {
                                            setNewMoney(1);
                                            setChoose([]);
                                            setTextArea("");
                                        }}>
                                        Cài lại
                                    </button>
                                    <div className="footer-game-money">
                                        <span>Số tiền:</span> <b>{choose.length != 0 && newMoney ? (choose.length * newMoney * 1000).toLocaleString('vi-VN') : 0}</b>
                                        <br />
                                        Số dư: {Math.floor(profile?.money).toLocaleString('vi-VN')}
                                    </div>
                                    <button type="submit" className="btn-sbmit">
                                        Đặt cược
                                    </button>
                                </div>
                            </form>
                        </div>
                    </AnimatedPage>
                </>
            )}

            <Results isOpen={isOpen1} total={total} closePopup={closePopup1} date={total && total[0]?.id_bet} />

            <History isOpen={isOpen2} closePopup={closePopup2} />
        </>
    );
}
export default Xoso;
