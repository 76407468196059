import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

function Home() {
    SwiperCore.use([Autoplay]);
    const url = `${process.env.REACT_APP_API_URL}/auth/getUser`;
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperMenu = {
        autoplay: false,
        slidesPerView: 5.3,
        centerInsufficientSlides: true,
        spaceBetween: 0,
        grabCursor: true
    };
    const swiperGame = {
        autoplay: true,
        slidesPerView: 3.6,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame1 = {
        autoplay: true,
        slidesPerView: 3.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame2 = {
        autoplay: false,
        slidesPerView: 2.2,
        centerInsufficientSlides: false,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame3 = {
        autoplay: false,
        slidesPerView: 1.1,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const searchParams = new URLSearchParams(window.location.search);
    const notifyParam = searchParams.get("notify");
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [profile, setProfile] = useState(null);
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [playGame, setPlayGame] = useState(null);
    const [product, setProduct] = useState(null);
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const getProfile = () => {
        axios
            .get(url, {})
            .then((res) => {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    useEffect(() => {
        getProfile();
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        setTimeout(() => {
            axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/miba`).then((res) => {
                setBet(res.data.t);
                if (bet != res.data.t) {
                    setBet(res.data.t);
                    localStorage.setItem("xsmb", JSON.stringify(res.data.t));
                }
            });
        }, 5000);
    }, []);
    useEffect(() => {
        if (profile) {
            axios.post(`${process.env.REACT_APP_API_URL}/casino/create`);
        }
    }, [profile]);
    const [activeOption, setActiveOption] = useState("0");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "111":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            case "61":
                a = "IMES";
                break;
            case "132":
                a = "SV388";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const menuGame = (title, product_type, game_type) => {
        setTitle(title);
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getgamelist`, { product_type, game_type })
            .then((res) => {
                if (res.data.data.length == 0) {
                    swal("Thông báo", "Sảnh game bảo trì", "error");
                    setLoading(false);
                } else {
                    setGame(res.data.data[0]);
                    setLoading(false);
                    setShow(true);
                }
            })
            .catch((err) => {
                swal("Thông báo", "Sảnh game bảo trì", "error");
                setLoading(false);
            });
    };
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(url, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    const [yoGame, setYoGame] = useState(false);
    const [yoPopup, setYoPopup] = useState(false);
    const [yoType, setYoType] = useState(1);
    const [yoMoney, setYoMoney] = useState(0);
    const [yoBalance, setYoBalance] = useState(0);
    const openYoGame = () => {
        if (profile === null) {
            return navigate("/login");
        }
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/logingame`)
            .then((res) => {
                setYoGame(res.data.data);
                setYoBalance(res.data.data?.AccountInfo?.Balance);
                setYoPopup(true);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
            });
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitYoGame = async () => {
        if (profile === null) {
            return navigate("/login");
        }
        if (isSubmitting) {
            return swal("Thông báo", "Bạn thao tác quá nhanh", "warning");
        }
        setLoading(true);
        const transactionAmount = Number(yoMoney?.replaceAll(".", "")?.replaceAll(",", ""));
        if (transactionAmount < 0) {
            setLoading(false);
            return swal("Chú ý", "Vui lòng nhập số tiền cần giao dịch", "warning");
        }
        const formData = {
            amount: transactionAmount,
            type: yoType
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/transfergame`, formData)
            .then((res) => {
                axios
                    .get(url, {})
                    .then((res) => {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    })
                    .catch((err) => localStorage.removeItem("user"));
                swal(yoType == 1 ? "Nạp tiền thành công" : "Rút tiền thành công", "", "success");
                setYoBalance(res.data.data.new_balance);
                setTimeout(() => {
                    setIsSubmitting(false);
                }, 3000);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
                setIsSubmitting(false);
            });
    };
    if (url.length != 38) return;
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Header profile={profile} />
            {swiperParams && notify && (
                <AnimatedPage>
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                        {notify ? (
                            <>
                                {notify[0].isShow === true && notify[0].title === "marquee" ? (
                                    <div className="marquees">
                                        <div>
                                            <CampaignIcon sx={{ fontSize: "22px" }} />
                                        </div>
                                        <div>
                                            <div
                                                className="chaychu"
                                                style={{ animation: "chuchay " + notify[0].content.length / 10 + "s linear infinite" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: notify[0].content
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div class="wallet-func">
                        <div class="wallet-func-item partner">
                            <Link to="/profile">
                                <img src={require("../../static/icon-menu.png")} />
                                <h3>Chức năng</h3>
                            </Link>
                        </div>
                        <div class="wallet-func-item partner">
                            <a href="https://WIN68ds.com/" target="_blank" rel="noreferrer">
                                <img src={require("../../static/icon-partner.png")} />
                                <h3>Thương Hiệu</h3>
                            </a>
                        </div>
                        <div class="wallet-func-item mobile">
                            <a href="#" target="_blank" rel="noreferrer">
                                <img src={require("../../static/icon_mobile.png")} />
                                <h3>Tải APP</h3>
                            </a>
                        </div>
                        <div class="wallet-func-item deposit">
                            <Link to="/recharge">
                                <img src={require("../../static/icon_deposit.png")} />
                                <h3>Nạp tiền</h3>
                            </Link>
                        </div>
                        <div class="wallet-func-item withdrawal">
                            <Link to="/withdraw">
                                <img src={require("../../static/icon_withdrawal.png")} />
                                <h3>Rút tiền</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="content-game-home">
                        <div className="content-game-left">
                            <div className="menu-game-home">
                                <div className={`menu-game-item ${activeOption === "0" ? "active" : ""}`} onClick={() => handleOptionClick("0")}>
                                    <img src={require("../../static/type_hot.png")} />
                                    <h3>Hot</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "1" ? "active" : ""}`} onClick={() => handleOptionClick("1")}>
                                    <img src={require("../../static/type_live.png")} />
                                    <h3>Casino</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "2" ? "active" : ""}`} onClick={() => handleOptionClick("2")}>
                                    <img src={require("../../static/type_egame.png")} />
                                    <h3>Nổ Hũ</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "3" ? "active" : ""}`} onClick={() => handleOptionClick("3")}>
                                    <img src={require("../../static/type_mpg.png")} />
                                    <h3>Bắn Cá</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "4" ? "active" : ""}`} onClick={() => handleOptionClick("4")}>
                                    <img src={require("../../static/type_animal.png")} />
                                    <h3>Đá Gà</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "5" ? "active" : ""}`} onClick={() => handleOptionClick("5")}>
                                    <img src={require("../../static/type_sport.png")} />
                                    <h3>Thể Thao</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "6" ? "active" : ""}`} onClick={() => handleOptionClick("6")}>
                                    <img src={require("../../static/type_chess.png")} />
                                    <h3>Game Bài</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "7" ? "active" : ""}`} onClick={() => handleOptionClick("7")}>
                                    <img src={require("../../static/type_lottery.png")} />
                                    <h3>Xổ Số</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "8" ? "active" : ""}`} onClick={() => handleOptionClick("8")}>
                                    <img src={require("../../static/type_esports.png")} />
                                    <h3>E-Sports</h3>
                                </div>
                            </div>
                        </div>
                        <div className="content-game-right">
                            {/*activeOption === "0" && (
                                <div className="api-game-flex2">
                                    <div className="api-game" onClick={() => launchGame({ product_type: "112", game_code: "SEX002" })}>
                                        <img alt="" src={require("../../static/popular/f744c336-9ff3-410e-97ab-b492ac40fe44.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "27", game_code: "DG0026" })}>
                                        <img alt="" src={require("../../static/popular/8c9dad63-0f67-4cdd-8536-67426348a561.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "121", game_code: "KM0009" })}>
                                        <img alt="" src={require("../../static/popular/d4586d84-f741-460b-86e2-29611b907c42.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "118", game_code: "WM0009" })}>
                                        <img alt="" src={require("../../static/popular/d4b29eb2-729b-4a58-b502-f7b9f588e361.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "16", game_code: "CQ0188" })}>
                                        <img alt="" src={require("../../static/popular/6eae6ccc-fd27-4764-aba2-089338e34e56.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "140", game_code: "JL0003" })}>
                                        <img alt="" src={require("../../static/popular/09021f77-1d01-439b-aa51-0ef2130372a2.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "55", game_code: "JDB012" })}>
                                        <img alt="" src={require("../../static/popular/8545be04-8c93-4a4e-8d64-10768cc530ba.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "102", game_code: "LCC043" })}>
                                        <img alt="" src={require("../../static/popular/e9b64b01-c289-4e11-9754-9ef12db92f1e.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "43", game_code: "MG0076" })}>
                                        <img alt="" src={require("../../static/popular/42009f7b-a32f-49b3-9e46-f7e06ad98dda.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                        <img alt="" src={require("../../static/popular/3cc285ec-7f05-4b07-b8e8-f20ab874118e.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "55", game_code: "JDB083" })}>
                                        <img alt="" src={require("../../static/popular/d351c4b2-47c6-4cfc-8a70-ee0b6d655521.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "140", game_code: "JL0008" })}>
                                        <img alt="" src={require("../../static/popular/e80f30c8-b51e-416c-902e-66a273e85a6a.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "102", game_code: "LCC038" })}>
                                        <img alt="" src={require("../../static/popular/01366713-6e7e-4161-8992-e6fdce7412f9.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "16", game_code: "CQ0232" })}>
                                        <img alt="" src={require("../../static/popular/1dc9a1d7-7440-490f-b9f3-79fe9e485f1b.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "140", game_code: "JL0046" })}>
                                        <img alt="" src={require("../../static/popular/154ce739-7fea-493b-b9f8-a7aa5073a6f7.png")} />
                                    </div>
                                </div>
                            )*/}
                            {activeOption === "0" && (
                                <div className="api-game-flex">
                                    <Link className="api-game" to="/xd45s">
                                        <img alt="" src={require("../../static/xd1.png")} />
                                        <span>XÓC DĨA 45S</span>
                                    </Link>
                                    <Link className="api-game" to="/xd60s">
                                        <img alt="" src={require("../../static/xd2.png")} />
                                        <span>XÓC DĨA 60S</span>
                                    </Link>
                                    <Link className="api-game" to="/tx45s">
                                        <img alt="" src={require("../../static/tx1.png")} />
                                        <span>TÀI XỈU 45S</span>
                                    </Link>
                                    <Link className="api-game" to="/tx60s">
                                        <img alt="" src={require("../../static/tx2.png")} />
                                        <span>TÀI XỈU 60S</span>
                                    </Link>
                                </div>
                            )}
                            {activeOption === "1" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                        <img alt="" src={require("../../images/games/live_wm.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                        <img alt="" src={require("../../images/games/live_dg.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                        <img alt="" src={require("../../images/games/live_sexy.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "86", game_code: "EG0001" })}>
                                        <img alt="" src={require("../../images/games/live_evo.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                        <img alt="" src={require("../../images/games/live_ag.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "111", game_code: "PTU002" })}>
                                        <img alt="" src={require("../../images/games/live_pt.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "2" && (
                                <div className="api-game-flex">
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "140", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/jili.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "55", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/jdb.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "43", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/mg.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "111", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/pt.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "4", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/ag.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "79", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/bbin.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "39", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/pp.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "16", "RNG")} style={{ width: "50%" }}>
                                        <img alt="" src={require("../../images/games/cq9.png")} />
                                    </div>
                                </div>
                            )}
                            {activeOption === "3" && (
                                <>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "140", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_jili.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "55", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_jdb.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "16", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_cq9.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "43", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_mg.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "111", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_pt.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "4" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "202", game_code: "WS1682" })}>
                                        <img alt="" src={require("../../images/games/ws168.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "132", game_code: "SV3002" })}>
                                        <img alt="" src={require("../../images/games/sv388.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "5" && (
                                <div className="api-game-flex">
                                    <div className="api-game" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                        <img alt="" src={require("../../images/games/saba.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "54", game_code: "SBO041" })}>
                                        <img alt="" src={require("../../images/games/sbo.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                        <img alt="" src={require("../../images/games/cmd.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "151", game_code: "UG2001" })}>
                                        <img alt="" src={require("../../images/games/ug.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                        <img alt="" src={require("../../images/games/fb.png")} />
                                    </div>
                                    <div className="api-game border">
                                        <img alt="" src={require("../../images/games/im.png")} />
                                        <div className="maintain" style={{ maxWidth: "calc(100% - 0.32rem)" }}>
                                            Đang bảo trì
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeOption === "6" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "102", game_code: "LCC001" })}>
                                        <img alt="" src={require("../../images/games/v8poker.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "121", game_code: "KM0001" })}>
                                        <img alt="" src={require("../../images/games/kingmaker.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "123", game_code: "KP0001" })}>
                                        <img alt="" src={require("../../images/games/kingpoker.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "7" && (
                                <div className="list-game">
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Nổi bật >>
                                    </div>
                                    <div className="box-miba">
                                        <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                            <img alt="" src={require("../../images/3mien/mienbac.png")} />
                                            <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")}>
                                                Đặt cược
                                            </div>
                                        </div>
                                        <div className="box-miba-right">
                                            <div className="box-text">
                                                Miền Bắc <img alt="" src={require("../../img/icon-hot.png")} className="icon-hot" />
                                            </div>
                                            <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                            <div className="box-miba-kq">
                                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                                    <div className="ball">
                                                        <img alt="" src={`/images/ball/${x}.png`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title">Xổ số nhanh</div>
                                    <div className="list-game-xs">
                                        <Link to="/xs75s/lo2">
                                            <img alt="" src={require(`../../images/mbmg.png`)} />
                                        </Link>
                                        <Link to="/xs120s/lo2">
                                            <img alt="" src={require(`../../images/st2p.png`)} />
                                        </Link>
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Nam >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMN?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmn/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMN[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Trung >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMT?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmt/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMT[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {activeOption === "8" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "99", game_code: "TF0001" })}>
                                        <div className="img-esports esport1"></div>
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "61", game_code: "IMES17" })}>
                                        <div className="img-esports esport2"></div>
                                    </div>
                                </>
                            )}
                            {activeOption === "10" && (
                                <div className="api-game-flex">
                                    <Link className="api-game">
                                        <img alt="" src={require("../../images/games/dogcat.png")} />
                                    </Link>
                                    <Link className="api-game">
                                        <img alt="" src={require("../../images/games/greedy.png")} />
                                    </Link>

                                    <Link className="api-game border" to="/tx45s" style={{ width: "33.33%" }}>
                                        <img alt="" src={require("../../images/tx45s.png")} />
                                    </Link>
                                    <Link className="api-game border" to="/xd45s" style={{ width: "33.33%" }}>
                                        <img alt="" src={require("../../images/xd45s.png")} />
                                    </Link>
                                    <Link className="api-game border" to="/xs75s/lo2" style={{ width: "33.33%" }}>
                                        <img alt="" src={require("../../images/mb75ss.png")} />
                                    </Link>
                                    {/*<Link className="api-game border" to="/tx60s" style={{ width: "33.33%" }}>
                                        <img alt="" src={require("../../images/tx60s.png")} />
                                    </Link>
                                    <Link className="api-game border" to="/xd60s" style={{ width: "33.33%" }}>
                                        <img alt="" src={require("../../images/xd60s.png")} />
                                    </Link>*/}
                                    <Link className="api-game border" to="/xs120s/lo2" style={{ width: "33.33%" }}>
                                        <img alt="" src={require("../../images/mb120ss.png")} />
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ padding: "0 0.32rem 2rem" }}>
                        <div className="content-game">
                            {/*activeOption !== "1" && (
                                <div className="baohanh">
                                    <img alt="" src={require("../../images/bao-hanh-va-sua-chua.png")} style={{ maxWidth: "8rem" }} />
                                    <div className="nangcap">Hệ thống đang nâng cấp...</div>
                                </div>
                            )*/}
                        </div>
                    </div>
                </AnimatedPage>
            )}
            <Footer />
            {isShow === true ? (
                <>
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header">{title}</div>
                            <div className="popup-content">
                                <div className="gameapi-list">
                                    {game != null &&
                                        game.map((item) => (
                                            <div className="gameapi-item" onClick={() => launchGame({ product_type: item.productType, game_code: item.tcgGameCode })}>
                                                <img
                                                    src={`https://images.21947392.com/TCG_GAME_ICONS/${item.productCode}/VI/${item.tcgGameCode}.png`}
                                                    onError={() => {
                                                        this.onerror = null;
                                                        this.src = `https://images.21947392.com/TCG_GAME_ICONS/${item.productCode}/${item.tcgGameCode}.png`;
                                                    }}
                                                    alt={item.gameName}
                                                />
                                                <div className="gameapi-name">{item.gameName}</div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <button onClick={() => setShow(false)} className="popup-close">
                                Đóng
                            </button>
                        </div>
                    </div>
                </>
            ) : null}
            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "16px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "16px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#000", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input">
                                    <input
                                        className="ipadd"
                                        type="text"
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div className="absolute-input" onClick={selectAll}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
            {profile && notify && isShow && notifyParam != null && (
                <>
                    {notify.map(
                        (item) =>
                            item.isShow &&
                            item.title === "popup" && (
                                <div className="popup-backdrop">
                                    <div className="popup-main">
                                        <div className="popup-times" onClick={() => setShow(false)}>
                                            &times;
                                        </div>
                                        <div className="popup-content" style={{ padding: "20px" }}>
                                            {item.content.includes("{") && item.content.includes("}") ? (
                                                <img
                                                    src={item.content.substring(item.content.indexOf("{") + 1, item.content.indexOf("}"))}
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                </>
            )}

            {yoPopup === true && yoGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setYoPopup(false)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">YoGame</div>
                        <div className="popup-content">
                            <ul className="tab-navigation tab-game">
                                <li className={yoType === 1 ? "item active" : "item"} onClick={() => setYoType(1)}>
                                    Nạp tiền
                                </li>
                                <li className={yoType === 2 ? "item active" : "item"} onClick={() => setYoType(2)}>
                                    Rút tiền
                                </li>
                            </ul>
                            <div className="form-sibo">
                                <input
                                    type="text"
                                    value={yoMoney}
                                    onClick={() => setYoMoney(null)}
                                    onChange={(e) => setYoMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    placeholder="Nhập số tiền"
                                />
                                <button onClick={submitYoGame}>Xác nhận</button>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư YoGame: <b>{yoBalance?.toLocaleString("vi-VN")}</b>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <button className="open-game" onClick={() => (window.location.href = `https://game.win68a.com?token=${yoGame?.Token}`)}>
                                Vào game
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Home;
